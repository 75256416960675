import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Accordion from "../components/accordion"
import Typography from "@material-ui/core/Typography"
import { Hidden } from "@material-ui/core"

const Suivi = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Suivi en Médecine Générale" />
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Suivi en Médecine Générale
        </Typography>
        <Accordion
          initialList={["types"]}
          entries={[
            {
              id: "types",
              header: "Suivi des différents types de chirurgie",
              element: (
                <Typography variant="body1" component={"span"}>
                  <ul>
                    <li>
                      <a href="/suivi_anneau">Anneau gastrique</a>
                    </li>
                    <li>
                      <a href="/suivi_sleeve">
                        Sleeve ou Gastrectomie en manchon
                      </a>
                    </li>
                    <li>
                      <a href="/suivi_bypass">By-pass gastrique</a>
                    </li>
                    <li>
                      <a href="/suivi_derivation">
                        Dérivation biliopancréatique
                      </a>
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              id: "evaluation",
              header: "Evaluation de l’activité physique",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    L’objectif est de viser une activité d’endurance adaptée aux
                    capacités et à l’âge du patient, d’au moins 30 minutes 5 jours
                    par semaine, associée à une activité de renforcement
                    musculaire 2 à 3 fois par semaine.
                  </Typography>
                </div>
              ),
            },
            {
              id: "signes",
              header: "Signes cliniques qui imposent de consulter en urgence",
              element: (
                <Typography variant="body1" component={"span"}>
                  <div style={{ fontWeight: "bold" }}>
                    Signes évocateurs de complications chirurgicales du premier
                    mois : la présence d’un de ces signes impose une
                    consultation immédiate aux urgences :
                  </div>
                  <ul>
                    <li>
                      une tachycardie ({">"}120 /min), une dyspnée, une
                      confusion.
                    </li>
                    <li>une agitation, une sensation de malaise.</li>
                    <li>
                      une fièvre inexpliquée, des douleurs abdominales (même en
                      l’absence de défense) et/ou vomissements importants.
                    </li>
                    <li>
                      hématémèse ou méléna.
                    </li>
                  </ul>
                  <div style={{ fontWeight: "bold" }}>
                    A chaque consultation on rappelle les signes d’alerte qui
                    imposent de consulter en urgence :
                  </div>
                  <ul>
                    <li>fièvre inexpliquée</li>
                    <li>sensation de malaise intense</li>
                    <li>douleurs abdominales ou dorsales</li>
                    <li>
                      vomissements répétés, impossibilité de boire ou de manger,
                      dysphagie
                    </li>
                    <li>méléna ou hématémèse</li>
                    <li>reflux gastro-œsophagien invalidant</li>
                    <li>
                      fourmillements (mains, pieds...), déséquilibre, troubles de
                      mémoire, ou autres signes neurologiques évocateurs de
                      carence vitaminique
                    </li>
                    <li>impression de déprime intense</li>
                    <li>
                      « toute modification récente de l’état qui inquiète le
                      patient »
                    </li>
                  </ul>
                  <div>
                    Point sur la diarrhée : une diarrhée chronique après une chirurgie
                    bariatrique doit faire évoquer un syndrome de malabsorption, qui peut être favorisée par la
                    maldigestion, la diminution de la surface d'absorption, ou une prolifération bactérienne dans l'anse
                    grêle exclue (SIBO). Dans ce contexte, nous préconisons d'essayer un traitement par probiotiques. En
                    cas d'échec, une antibiothérapie séquentielle peut être indiquée (se rapprocher du centre de référence).
                  </div>
                  <div style={{fontStyle: 'italic', marginTop: 12}}>
                    NB : plusieurs situations peuvent favoriser une carence en vitamine B1, notamment la présence de
                    vomissements répétés, de contexte de consommation d'OH, de perte de poids majeure... Ces situations
                    nécessitent la prise d'un avis spécialisé urgent pour bilan et supplémentation.
                  </div>
                </Typography>
              ),
            },
            {
              id: "complications",
              header: "Principales complications",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    Rappel des principales complications possibles après une chirurgie 
                    bariatrique (à différencier des effets indésirables) :
                  </Typography>
                  <Typography variant="body1" component={"span"}>
                    <table style={{marginTop: 20}}>
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>
                            <strong>Complications précoces (&#60; J30)</strong>
                          </th>
                          <th style={{ width: "50%" }}>
                            <strong>Complications tardives</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Hémorragie</td>
                          <td>Lâchage des sutures</td>
                        </tr>
                        <tr>
                          <td>Infection digestive, abcès intra-péritonéal</td>
                          <td>Eventration sur orifice de trocart</td>
                        </tr>
                        <tr>
                          <td>Abcès ou hématome de paroi</td>
                          <td>Fistule</td>
                        </tr>
                        <tr>
                          <td>Lâchage des sutures</td>
                          <td>Ulcère anastomotique</td>
                        </tr>
                        <tr>
                          <td>Eventration sur orifice de trocart</td>
                          <td>Sténose du montage chirurgical</td>
                        </tr>
                        <tr>
                          <td>Fistule</td>
                          <td>Twist du tube gastrique (sleeve)</td>
                        </tr>
                        <tr>
                          <td>Perforation gastrique (anneau)</td>
                          <td>Occlusion par hernie interne (by-pass)</td>
                        </tr>
                        <tr>
                          <td>Sténose gastrique (sleeve)</td>
                          <td>Occlusion sur bride</td>
                        </tr>
                        <tr>
                          <td>Sténose anastomotique (by-pass)</td>
                          <td>Glissement ou migration de l’anneau gastrique</td>
                        </tr>
                        <tr>
                          <td>Twist du tube gastrique (sleeve)</td>
                          <td>Lithiase biliaire</td>
                        </tr>
                        <tr>
                          <td>Occlusion digestive</td>
                          <td>RGO sévère</td>
                        </tr>
                        <tr>
                          <td>Dumping syndrome précoce</td>
                          <td>Dumping syndrome précoce ou tardif / hypoglycémie, Carence vitaminique</td>
                        </tr>
                        <tr>
                          <td>Embolie pulmonaire / thrombose veineuse profonde</td>
                          <td>Dénutrition, Troubles psychiatriques (dépression, TCA, exogénose…)</td>
                        </tr>
                      </tbody>
                    </table>
                  </Typography>
                  <Typography
                      variant="caption"
                      color="inherit"
                      style={{
                        textAlign: "center",
                        display: "block",
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      <a href="https://www.fmcgastro.org/textes-postus/postu-2016-paris/chirurgie-de-lobesite-risques-et-gestion-des-complications/">
                        <i>
                          https://www.fmcgastro.org/textes-postus/postu-2016-paris/chirurgie-de-lobesite-risques-et-gestion-des-complications/
                        </i>
                      </a>
                  </Typography>
                </div>
              ),
            },
            {
              id: "dumping",
              header: "Dumping syndrome",
              element: (
                <div>
                  <Hidden smDown>
                    <Typography variant="body1" component={"span"}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ paddingBottom: 40 }}></th>
                            <th style={{ width: "40%" }}>
                              <strong>Dumping précoce</strong>
                            </th>
                            <th style={{ width: "40%" }}>
                              <strong>Dumping tardif</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Apparition</td>
                            <td>
                              Quelques semaines à quelques mois après
                              l’intervention
                            </td>
                            <td>
                              A partir de 12 à 18 mois après l’intervention
                            </td>
                          </tr>
                          <tr>
                            <td>Moment de survenue</td>
                            <td>
                              Pendant, ou immédiatement après le repas (maximum
                              30 minutes après)
                            </td>
                            <td>Entre 1 et 4h après le repas</td>
                          </tr>
                          <tr>
                            <td>Symptômes</td>
                            <td>
                              <div>
                                <strong>Symptômes gastro-intestinaux :</strong>{" "}
                                douleurs abdominales, diarrhées, nausées,
                                borborygmes
                              </div>
                              <div>
                                <strong>Symptômes vasomoteurs :</strong>{" "}
                                palpitations, tachycardie, flush, sueurs,
                                hypotension
                              </div>
                            </td>
                            <td>
                              <div>
                                <strong>Symptômes adrénergiques :</strong>{" "}
                                palpitations, tremblements, sueurs
                              </div>
                              <div>
                                <strong>Symptômes neuroglycopéniques :</strong>{" "}
                                faiblesse, fatigue, confusion, sensation de
                                perte de connaissance, vision floue/diplopie,
                                syncope
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingRight: 12 }}>
                              Physiopathologie
                            </td>
                            <td>
                              Le dumping précoce est la conséquence d’un passage
                              rapide d’aliments peu digérés à haut pouvoir
                              osmotique dans le jéjunum, provoquant un passage
                              du liquide intravasculaire dans la lumière
                              intestinale, entrainant une hypovolémie relative.
                            </td>
                            <td>
                              Le dumping tardif est causé par une hypoglycémie
                              secondaire à une réponse insulinique excessive.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingRight: 12 }}>
                              Gravité
                            </td>
                            <td>
                              Faible à moyenne
                            </td>
                            <td>
                              Sévère
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingRight: 12 }}>
                              Prise en charge (à titre indicatif, voir <a href="https://www.revmed.ch/RMS/2017/RMS-N-555/Prise-en-charge-pluridisciplinaire-des-syndromes-de-dumping-apres-chirurgie-bariatrique" target="_blank">article</a> pour les conseils détaillés)
                            </td>
                            <td>
                              Limiter la consommation d'aliments riches en sucres simples ou en graisses
                            </td>
                            <td>
                              Fractionnement des repas, avec diminution de la part des féculents de chaque repas, et consommation d'un féculent environ 1h30 après les repas (pain complet, biscottes complètes…)
                              Favoriser les aliments à faible IG
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Typography
                        variant="caption"
                        color="inherit"
                        style={{
                          textAlign: "center",
                          display: "block",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        <a href="https://www.revmed.ch/RMS/2017/RMS-N-555/Prise-en-charge-pluridisciplinaire-des-syndromes-de-dumping-apres-chirurgie-bariatrique">
                          <i>
                            https://www.revmed.ch/RMS/2017/RMS-N-555/Prise-en-charge-pluridisciplinaire-des-syndromes-de-dumping-apres-chirurgie-bariatrique
                          </i>
                        </a>
                      </Typography>
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="inherit"
                    >
                      Apparition
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping précoce
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Quelques semaines à quelques mois après l’intervention
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping tardif
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      A partir de 12 à 18 mois après l’intervention
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="inherit"
                    >
                      Moment de survenue
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping précoce
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Pendant, ou immédiatement après le repas (maximum 30
                      minutes après)
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping tardif
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Entre 1 et 4h après le repas
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="inherit"
                    >
                      Symptômes
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping précoce
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      component={"span"}
                      style={{ marginBottom: 20, display: "block" }}
                    >
                      <div>
                        <i>Symptômes gastro-intestinaux :</i> douleurs
                        abdominales, diarrhées, nausées, borborygmes
                      </div>
                      <div>
                        <i>Symptômes vasomoteurs :</i> palpitations,
                        tachycardie, flush, sueurs, hypotension
                      </div>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping tardif
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      component={"span"}
                      style={{ marginBottom: 20, display: "block" }}
                    >
                      <div>
                        <i>Symptômes adrénergiques :</i> palpitations,
                        tremblements, sueurs
                      </div>
                      <div>
                        <i>Symptômes neuroglycopéniques :</i> faiblesse,
                        fatigue, confusion, sensation de perte de connaissance,
                        vision floue/diplopie, syncope
                      </div>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="inherit"
                    >
                      Physiopathologie
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping précoce
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Le dumping précoce est la conséquence d’un passage rapide
                      d’aliments peu digérés à haut pouvoir osmotique dans le
                      jéjunum, provoquant un passage du liquide intravasculaire
                      dans la lumière intestinale, entrainant une hypovolémie
                      relative.
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping tardif
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Le dumping tardif est causé par une hypoglycémie
                      secondaire à une réponse insulinique excessive.
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="inherit"
                    >
                      Gravité
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping précoce
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Faible à moyenne
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping tardif
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Sévère
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="inherit"
                    >
                      Prise en charge (à titre indicatif, voir <a href="https://www.revmed.ch/RMS/2017/RMS-N-555/Prise-en-charge-pluridisciplinaire-des-syndromes-de-dumping-apres-chirurgie-bariatrique" target="_blank">article</a> pour les conseils détaillés)
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping précoce
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Limiter la consommation d'aliments riches en sucres simples ou en graisses
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline" }}
                      color="inherit"
                    >
                      Dumping tardif
                    </Typography>
                    <Typography
                      variant="body1"
                      color="inherit"
                      style={{ marginBottom: 20 }}
                    >
                      Fractionnement des repas, avec diminution de la part des féculents de chaque repas, et consommation d'un féculent environ 1h30 après les repas (pain complet, biscottes complètes…)
                      Favoriser les aliments à faible IG
                    </Typography>
                    <Typography
                      variant="caption"
                      color="inherit"
                      style={{
                        textAlign: "center",
                        display: "block",
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      <a href="https://www.revmed.ch/RMS/2017/RMS-N-555/Prise-en-charge-pluridisciplinaire-des-syndromes-de-dumping-apres-chirurgie-bariatrique">
                        <i>
                          https://www.revmed.ch/RMS/2017/RMS-N-555/Prise-en-charge-pluridisciplinaire-des-syndromes-de-dumping-apres-chirurgie-bariatrique
                        </i>
                      </a>
                    </Typography>
                  </Hidden>
                </div>
              ),
            },
            {
              id: "sociale",
              header: "Evaluation psychologique et sociale",
              element: (
                <div>
                  <Typography variant="body1" color="inherit">
                    La perte de poids peut entraîner des modifications
                    psychologiques plus ou moins faciles à gérer pour le
                    patient. Une période d’adaptation au changement est souvent
                    nécessaire, pour le patient et pour son entourage (couple,
                    famille, environnement socioprofessionnel, etc.).
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ marginTop: 16 }}
                  >
                    Le suivi par un psychiatre est recommandé pour les patients qui 
                    présentaient avant l’intervention des troubles du comportement 
                    alimentaire ou une autre pathologie psychiatrique.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ marginTop: 16 }}
                  >
                    Un suivi psychologique est fortement conseillé pour les autres patients.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ marginTop: 16 }}
                  >
                    Il est important de rechercher la présence de dépression, d'idées suicidaires, de présence d'un TCA ou encore d'exogénose.
                  </Typography>
                </div>
              ),
            },
          ]}
        />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default Suivi
